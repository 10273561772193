import { Injectable } from '@angular/core';

/**
 * This service is in charge of managing the information that will only
 * be required within the angular application.
 */
@Injectable()
export class LocalStorageService {
    constructor() {}

    
}
