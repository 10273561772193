import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

// Models
import { ResponseData } from 'src/app/platform/models/utils';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public tokenValidation = new BehaviorSubject<boolean>(true);
  options: object = {};

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Execute a call to the api
   */
  request(method: string, path: string, data: any = {}, header?: any, apiUrl: string | null = null): Observable<object> {
    const headersSetup = {
      headers: new HttpHeaders(header),
      body: '',
      params: new HttpParams(),
    };

    if (method === 'post' || method === 'put' || method === 'delete') {
      if (headersSetup.headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
        const bodySetup = new URLSearchParams(Object.keys(data).map(key => [key, data[key]]));

        headersSetup.body = bodySetup.toString();
      } else {
        headersSetup.body = data;
      }
    } else if (method === 'get') {
      headersSetup.params = new HttpParams(data);
    }

    this.tokenValidation.next(true);

    return this.http.request(method, ((apiUrl !== null && apiUrl !== '') ? apiUrl : environment.apiUrl) + path, headersSetup).pipe(
      catchError(error => {
        throw this.requestInterceptor(error, this);
      }),
    );
  }

  /**
   * Prepare and format an success response
   */
  successDataPrepare(message: string, result: any): ResponseData{
    const response = new ResponseData(true, message, result);

    // console.log('HANDLE SUCCESS', response);

    return response;
  }

  /**
   * Prepare and format an error response
   */
  errorDataPrepare(message: string, result: any): ResponseData{
    const response = new ResponseData(false, message, result);

    // console.log('HANDLE ERROR', response);

    return response;
  }

  /**
   * Intercepts api calls and parses headers to execute actions
   */
  private requestInterceptor(error: HttpErrorResponse, service: ApiService): object {
    if (error.error instanceof ErrorEvent) {
      console.error('API', 'An error occurred:', error.error.message);
    } else {
      console.error('API', 'Backend returned code ' + error.status + ', body was: ' + error.message);
    }

    if (typeof error.status !== 'undefined' && (error.status === 0 || error.status === 401)) {
      console.error('API', 'Token expiration');

      this.tokenValidation.next(false);
    } else if (error.status === 0) {
      console.error('API', 'Backend not available');

      return {status: false, data: {message: 'El servicio no esta disponible momentáneamente. Estamos trabajando para solucionar este problema.'}};
    }

    return {status: false, data: {message: 'Ocurrió un error al solicitar la información. Estamos trabajando para solucionar este problema.'}};
  }
}
