import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
declare let fbq:Function; // fbq function declaration 
declare let gtag:Function; // gtag function declaration 

// Models
import { MetaData, PriceData, ResponseData } from 'src/app/platform/models/utils';
import { formHasError, validateEmail, validatePhone, validateCUIT, validateCBU } from 'src/app/platform/utils/functions';

// Services
import { CommonService } from 'src/app/platform/services/common.service';
import { ZohoService } from 'src/app/platform/services/zoho.service';
import { UiService } from 'src/app/platform/services/ui.service';
import { AuthService } from 'src/app/modules/auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  formType = 'web';
  formFlow = 'b';
  formAgent = false;
  formValidate = false;
  formSendZoho = true;
  formPromotion = false;

  params: any;
  step:number = 1;
  loading = false;
  resume = new PriceData();

  dataTaxConditions: Array<MetaData> = [];
  dataAgents: Array<string> = [];
  dataProvinces: Array<MetaData> = [];
  dataAreas: Array<MetaData> = [];
  dataPrices: Array<PriceData> = [];
  
  dataLicenses: Array<number> = new Array(10).fill(0).map((x, i) => i + 1);
  
  formData: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,    
    private uiService: UiService,
    private zohoService: ZohoService,
    private authService: AuthService,
    private commonService: CommonService,
  ) {
    let formInputs: {[key: string]: any} = {
      name: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, this.validateEmail()]),
      phone: new UntypedFormControl('', [Validators.required, this.validatePhone()]),
      cuit: new UntypedFormControl('', [Validators.required, this.validateCUIT()]),
      taxCondition: new UntypedFormControl('', [Validators.required]),
      province: new UntypedFormControl('', [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
      cbu: new UntypedFormControl('', [Validators.required, this.validateCBU()]),
      plan: new UntypedFormControl('', [Validators.required]),
      licenses: new UntypedFormControl('', [Validators.required]),
    }

    this.route.queryParams.subscribe((params) => {
      this.params = params;
      
      if(typeof this.params['formPromotion'] != 'undefined'){
        this.formPromotion = this.params['formPromotion'] === 'true';
      }

      if(typeof this.params['formType'] != 'undefined' && ['web', 'desktop'].includes(this.params['formType'])){
        this.formType = this.params['formType'];
      }
      
      if(typeof this.params['formFlow'] != 'undefined' && ['a', 'b'].includes(this.params['formFlow'])){
        this.formFlow = params['formFlow'];
      }

      if(typeof this.params['formAgent'] != 'undefined' && this.params['formAgent'] === 'true'){
        this.formAgent = true;

        formInputs['agent'] = new UntypedFormControl('', [Validators.required]);
      }

      if(typeof this.params['formSendZoho'] != 'undefined' && this.params['formSendZoho'] === 'false'){
        this.formSendZoho = false;
      }
    }); 
    
    if(this.formType == 'web'){
      formInputs['area'] = new UntypedFormControl('', [Validators.required]);
      formInputs['password'] = new UntypedFormControl('', [Validators.required, this.validatePassword()]);
    }

    if(this.formAgent){
      formInputs['agent'] = new UntypedFormControl('', [Validators.required]);
    }
   
    this.formData = this.formBuilder.group({...formInputs, });

    this.uiService.showSpinner(true);
    
    let loadAreas = this.commonService.areasGet()
    let loadAgents = this.commonService.agentsGet();
    let loadProvinces = this.commonService.provincesGet();
    let loadTaxConditions = this.commonService.taxConditionsGet();
    let loadPrices = this.commonService.pricesGet((this.params['locale'] === 'cl') ? 'es_CL' : 'es_AR');
    
    Promise.all([loadAreas, loadAgents, loadProvinces, loadTaxConditions, loadPrices]).then(values => {
      this.uiService.showSpinner(false);

      this.dataAreas = values[0].result;
      this.dataAgents = values[1].result;
      this.dataProvinces = values[2].result;
      this.dataTaxConditions = values[3].result;
      
      values[4].result.planes.forEach((price: any) => {
        this.dataPrices.push(new PriceData({...price, ...values[4].result.detalles}, this.formPromotion))
      });

      this.formData.patchValue({
        name: (this.params['nombre'] != null ? this.params['nombre'] : '') + (this.params['apellido'] != null ? ' ' + this.params['apellido'] : ''),
        email: (this.params['email'] != null ? this.params['email'] : ''),
        phone: (this.params['telefono'] != null ? this.params['telefono'] : ''),
        area: this.dataAreas.find(item => String(item.id) === String(this.params['rubro'])),
        cuit: (this.params['cuit'] != null ? this.params['cuit'] : ''),
        taxCondition: this.dataTaxConditions.find(item => String(item.id) === String(this.params['condicionfiscal'])),
        province: this.dataProvinces.find(item => String(item.id) === String(this.params['provincia'])),
        country: (this.params['locale'] === 'cl') ? 'Chile' : 'Argentina',
        cbu: (this.params['cbu'] != null ? this.params['cbu'] : ''),
        licenses: (this.params['licencias'] != null ? Number(this.params['licencias']) : '1'),
        plan: this.dataPrices.find(item => String(item.id) === String(this.params['plan'])) || this.dataPrices.find(item => String(item.id) === String(4)),
      });

      if(this.params['plan'] !== undefined && this.params['plan'] != ''){
        this.onPlanChange();
      }

      this.resumeUpdate(1);
    }).catch(() => {
      this.uiService.showSpinner(false);
      this.uiService.dialogShow('', 'En este momento no podemos obtener los datos para que puedas solicitar el alta, te pedimos que actualices la pagina. Si el problema continúa contacta con un agente haciendo <a href="https://www.controlcomercio.com/contacto/" targe="_blank">click aquí</a>.', 'error');
    });
  }

	ngOnInit(): void { }

	resumeUpdate(licenses: number){
		this.resume = (this.formData.get('plan')?.value instanceof PriceData) ? this.formData.get('plan')?.value : this.dataPrices.find(item => [4, 8].includes(item.id));
    
		/*this.resume.base = price.base;
		this.resume.baseDiscount = price.base;
		this.resume.basePromotion = price.basePromotion;
		this.resume.valueSubtotal = price.base;
		this.resume.valueIVA = discount(price.valueIVA, licenses);
		this.resume.valueTotal = Math.ceil(discount((price.base + price.valueIVA), licenses));*/
	}

  onNext(): void{
    this.formValidate = true;

    let validate = false;
    console.log(this.formData);
    if(
      this.formData.get('name')?.value != '' &&
      this.formData.get('email')?.value != '' &&
      this.formData.get('phone')?.value != ''
    ){
      if(this.formType == 'web'){
        if(
          this.formData.get('area')?.value.name &&
          this.formData.get('password')?.value
        ){
          validate = true;
        } else {
          validate = false;
        }
      } else {
        validate = true;
      }
    }
          
    if(validate){
      this.step = 2;
      this.formValidate = false;
    }
  }

  onBack(): void {
    this.step = 1;
  }

  onPlanChange(){
    let licenses = this.formData.get('plan')?.value.licenses;

    this.formData.patchValue({licenses: licenses});

    this.resumeUpdate(licenses);
  }

  onRegister(){
    this.formValidate = true;
    console.log(this.formData.value);

    if (this.formData.valid) {
      this.uiService.showSpinner(true);

      if(this.formType == 'web'){
        let countryCode = ((this.formData.value.country === 'Chile') ? '+569' : '+549');
        
        // Send data to register in the web platform 
        this.authService.registerFull({
          name: this.formData.value.name,
          email: this.formData.value.email,
          phone: countryCode + this.formData.value.phone.replace(countryCode, ''),
          rubro: this.formData.value.area.id,
          cuit: this.formData.value.cuit,
          taxCondition: this.formData.value.taxCondition.id,
          province: this.formData.value.province.id,
          country: this.formData.value.country,
          cbu: this.formData.value.cbu,
          licenses: this.formData.value.licenses,
          password: this.formData.value.password,
          agent: this.formData.value.agent,
          referer: (typeof window.location.href !== 'undefined' && window.location.href !== '') ? window.location.href : 'https://registro.controlcomercio.com',
        }).then((success: ResponseData) => {
            this.uiService.showSpinner(false);
                
            console.log('REGISTER', success);

            this.onLogin().then((url) => {
              window.location.href = url
            });
    
            this.trackEventRegister();                        
            
            // Send data to Zoho
            /*  this.onZohoSend().then(() => {
				        this.uiService.showSpinner(false);
                
                console.log('REGISTER', success);

                this.onLogin().then((url) => {
                  window.location.href = url
                });
				
				        this.trackEventRegister();
              }).catch(() => {
                this.uiService.showSpinner(false);
                    
                this.onLogin().then((url) => {
                  window.location.href = url
                });
				
                this.trackEventRegister();
				      });*/
          }).catch((error: ResponseData) => {
            this.uiService.showSpinner(false);

            if(error.message === 'Email ya registrado como cliente'){
              this.onBack();
              this.uiService.dialogShow('', 'Esta dirección ya se encuentra registrada como cliente. Si tenias una cuenta y se dió de baja, contactate con nosotros para que podamos reactivar tu cuenta.', 'error');
            } else {
              this.uiService.dialogShow('', (error.message != '') ? error.message : 'Se produjo un error al intentar registrar tus datos (E0002). Intentá nuevamente, si el problema continúa, por favor contacta con un agente de soporte para finalizar la solicitud.' , 'error');
            }
          });
      } else if (this.formType == 'desktop') {
        // Send data to Zoho
        this.onZohoSend().then(() => {
          this.uiService.showSpinner(false);
            
          // Redirect to download page
          window.location.href = 'https://controlcomercio.com/landing/desktop-download?nombre=' + this.formData.get('name')?.value + '&email=' + this.formData.get('email')?.value;
        }).catch(() => {
          this.uiService.showSpinner(false);
            
          this.uiService.dialogShow('', 'Se produjo un error al intentar registrar tus datos (E0001). Por favor contacta con un agente de soporte para finalizar la solicitud.', 'error');
        });
      }
    }
  }

  onZohoSend(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if(this.formSendZoho){
        this.zohoService.formRegister(
          this.formType,
          this.formData.get('name')?.value,
          this.formData.get('email')?.value,
          this.formData.get('phone')?.value,
          this.formData.get('area')?.value.name,
          this.formData.get('cuit')?.value,
          this.formData.get('taxCondition')?.value.id,
          this.formData.get('province')?.value.name,
          this.formData.get('country')?.value,
          this.formData.get('agent')?.value,
          this.formData.get('cbu')?.value,
          this.formData.get('licenses')?.value
        ).then((success: ResponseData) => {
          resolve(true);
        }).catch((error: ResponseData) => {
          reject(false);
        });
      } else {
        resolve(true);
      }
    });
  }

  trackEventRegister(): void{
    fbq('track', 'Subscribe', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'});
    gtag('event', 'conversion', { send_to: 'AW-981319856/AlZNCOaOkbEYELCB99MD'});
    gtag('event', 'purchase', { transaction_id: 'REGISTER_' + Date.now().toString(36) + Math.random().toString(36).substr(2), value: 0, currency: 'USD', items: [ { item_id: 'SUBSCRIBE', item_name: 'Subscribe', }]});
    gtag('event', 'generate_lead', { currency: 'USD', value: 0 });
  }
  
  onLogin(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.authService.login(
        this.formData.get('email')?.value,
        this.formData.get('password')?.value
      ).then((success: ResponseData) => {
        resolve('https://controlcomercioweb.com/app/ingreso?token=' + success.result.token);
      }).catch((error) => {
        resolve('https://controlcomercioweb.com/app/ingreso');
      });
    });
  }

  formHasError(formcontrol: string, data: string): any {
    const fields: any = {
      email: {
        invalid:  'El email es inválido.',
      },
      phone: {
        invalid:  'Ingresar 10 números sin 0 inicial.',
      },       
      password: {
        invalid:  'La contraseña debe tener 4 o más dígitos.',
      },     
      cuit: {
        invalid:  'El número de CUIT / CUIL es inválido.',
      },
      cbu: {
        invalid:  'El número de CBU es inválido.',
      },
    };

    if(this.formValidate){
      return formHasError(this.formData, fields, formcontrol, data);
    }
  }
  
  private validateEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validationResult: ValidationErrors | null = (validateEmail(String(control.value)) === true) ? null : { invalid: true };

      if (typeof validationResult === 'undefined' || control.value == '') {
        validationResult = null;
      }

      return validationResult;
    };
  }

  private validatePhone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validationResult: ValidationErrors | null = (validatePhone(control.value)) ? null : { invalid: true };

      if (typeof validationResult === 'undefined' || control.value == '') {
        validationResult = null;
      }

      return validationResult;
    };
  }

  private validatePassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validationResult: ValidationErrors | null = (control.value.length >= 4) ? null : { invalid: true };

      if (typeof validationResult === 'undefined' || control.value == '') {
        validationResult = null;
      }

      return validationResult;
    };
  }

  private validateCUIT(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validationResult: ValidationErrors | null = (validateCUIT(String(control.value)) === true) ? null : { invalid: true };

      if (typeof validationResult === 'undefined' || control.value == '') {
        validationResult = null;
      }

      return validationResult;
    };
  }

  private validateCBU(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = validateCBU(String(control.value));
      
      let validationResult: ValidationErrors | null = (isValid === true) ? null : { invalid: true };

      if (typeof validationResult === 'undefined' || control.value == '') {
        validationResult = null;
      }

      return validationResult;
    };
  }
}
