export class ScreenLoaderData {
    title: string;
    message: string;
    icon: string;
    loading: boolean;
    loadingDiameter: number;

    constructor(title: string, message?: string, icon?: string, loading?: boolean, loadingDiameter?: number) {
        this.title = title;
        this.message = (typeof message !== 'undefined' && message !== '') ? message : '';
        this.icon = (typeof icon !== 'undefined' && icon !== '') ? icon : '';
        this.loading = (typeof loading !== 'undefined') ? loading : false;
        this.loadingDiameter = (typeof loadingDiameter !== 'undefined' && loadingDiameter > 0) ? loadingDiameter : 20;
    }
}
