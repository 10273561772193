import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

// Modules
import { AppRoutingModule } from 'src/app/app-routing.module';
import { UiModule } from 'src/app/platform/ui.module';
import { PlatformModule } from 'src/app/platform/platform.module';
import { AuthModule } from 'src/app/modules/auth/auth.module';

// Directives
import { PhoneMaskDirective } from 'src/app/platform/utils/phone.directive';

// Componentes
import { AppComponent } from 'src/app/app.component';


@NgModule({
  declarations: [
    AppComponent,
    PhoneMaskDirective
  ],
  imports: [
    UiModule,
    CommonModule,
    BrowserModule,
    PlatformModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AuthModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-WVSJ94T',
    })
  ],
  exports: [
    PhoneMaskDirective
  ],
  //entryComponents: [],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
