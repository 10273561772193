import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class DialogData {
  public title: string;
  public message: string;
  public type: string;
  public buttonCancelText: string;
  public buttonConfirmText: string;
  public action: () => void;

  constructor() {
    this.title = '';
    this.message = '';
    this.type = '';
    this.buttonCancelText = '';
    this.buttonConfirmText = '';
    this.action = () => {};
  }

  configData(parameters: any): DialogData {
    this.title = (typeof parameters.title !== 'undefined') ? parameters.title : '' ;
    this.message = (typeof parameters.message !== 'undefined') ? parameters.message : '';
    this.type = (typeof parameters.type !== 'undefined') ? parameters.type : '';
    this.buttonCancelText = (typeof parameters.buttonCancelText !== 'undefined') ? parameters.buttonCancelText : '';
    this.buttonConfirmText = (typeof parameters.buttonConfirmText !== 'undefined') ? parameters.buttonConfirmText : '';
    this.action = (typeof parameters.action !== 'undefined') ? parameters.action : '';

    return this;
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: './ui-dialog.component.html',
  styleUrls: ['./ui-dialog.component.scss']
})
export class UiDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.data = new DialogData().configData(this.data);
  }

  ngOnInit(): void { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    if (this.data.action && {}.toString.call(this.data.action) === '[object Function]') {
      this.data.action();
    }
  }
}
