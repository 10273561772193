import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment';

export function dateConvertTimezone(date: Date, timezone: string): string {
    const newDate = date.toLocaleString('en-US', {timeZone: timezone});

    return newDate;
}

export function objectToString(data: any): string {
    const parameters: any = {};

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            if (data[key] !== null && typeof data[key] !== 'undefined' && data[key] !== '') {
                parameters[key] = data[key];
            }
        }
    }

    if (Object.keys(parameters).length > 0) {
        return '?' + Object.entries(parameters).map(([key, val]) => `${key}=${val}`).join('&');
    }

    return '';
}

export function dateFormat(date: string | Moment, format: string, originFormat: string = ''): string {
    if (date !== '' && date !== null && typeof date !== 'undefined') {
        if (originFormat !== '') {
            return moment(date, originFormat).format(format);
        }

        return moment(date).format(format);
    }

    return '';
}

export function capitalize(str: string, lower: boolean = true): string {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

export function validateEmail(email: string): boolean{
    const validate = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    return validate.test(email);
}

export function validateCUIT(cuit: string): any {
    if (cuit === null || cuit === '') {
        return 'Por favor, ingrese un CUIT/DNI válido';
    }
    if (cuit.length !== 8 && cuit.length !== 11) {
        return 'Por favor, ingrese un CUIT/DNI válido';
    }
    if (cuit.length === 8) {
        return true;
    }

    const mult = new Array(5, 4, 3, 2, 7, 6, 5, 4, 3, 2);
    let total = 0;

    for (let i = 0; i < mult.length; i++) {
        total += parseInt(cuit[i]) * mult[i];
    }

    const resto = total % 11;
    const calculado = resto === 0 ? 0 : resto === 1 ? 9 : 11 - resto;
    const digito = parseInt(cuit[10]);

    if (calculado === digito) {
        return true;
    } else {
        return 'El CUIT/DNI ingresado no es válido.';
    }
}

export function validateCBU(cbu: string): any {
    try{
        cbu = cbu.replace('-', '').replace('/', '').replace('(', '').replace(')', '');

        if(
			cbu.substring(0, 5) === '00000' ||
			cbu.substring(0, 5) === '01430' ||
			cbu.substring(0, 4) === '1430' ||
			cbu.substring(0, 4) === '4530' ||
			cbu.substring(0, 4) === '1580'
		){
        return 'CVU';
		}
		
		if (cbu.length !== 22 || cbu === '0000000000000000000000'){
            return 'Por favor, ingrese el CBU correctamente';
        }

        const ponderador = '97139713971397139713971397139713';
        let i: any;
        let nDigito: any;
        let nPond: any;
        let bloque1: any;
        let bloque2: any;
        let nTotal = 0;

        bloque1 = '0' + cbu.substring(0, 7);

        for (let it = 0; it <= 7; it++) {
            nDigito = bloque1.charAt(it);
            nPond = ponderador.charAt(it);
            nTotal = nTotal + (nPond * nDigito) - ((Math.floor(nPond * nDigito / 10)) * 10);

            if (isNaN(nTotal)) {
                return 'Por favor, ingrese el CBU correctamente';
               
            }
        }

        i = 0;

        while (((Math.floor((nTotal + i) / 10)) * 10) !== (nTotal + i)) {
            i = i + 1;
        }

        if (Number(cbu.substring(7, 8)) !== i) {
            return 'Por favor, ingrese un CBU válido';
        }

        nTotal = 0;
        bloque2 = '000' + cbu.substring(8, 21);

        for (i = 0; i <= 15; i++) {
            nDigito = bloque2.charAt(i);
            nPond = ponderador.charAt(i);
            nTotal = nTotal + (nPond * nDigito) - ((Math.floor(nPond * nDigito / 10)) * 10);
        }

        i = 0;

        while (((Math.floor((nTotal + i) / 10)) * 10) !== (nTotal + i)) {
            i = i + 1;
        }

        if (Number(cbu.substring(21, 22)) !== i) {
            return 'Por favor, ingrese un CBU válido';
        }

        return true;
    }
    catch (ex){
        return 'Por favor, ingrese un CBU válido';
    }
}

export function validatePhone(telefono: string): boolean {
    return RegExp(/^[1-9]{1}[0-9]{9}$/).test(telefono); // 10 numeros sin permitir 0 inicial
    // return RegExp(/^[1-9]{1}[0-9]{7}([0-9]+)?$/).test(telefono); // Entre 8 y mas numeros sin permitir 0 inicial
    // return RegExp(/^[0-9]{10}([0-9]{1})?$/).test(telefono); // Entre 10 y 11 numeros permitiendo 0 inicial
}

export function validateCardNumber(numero: string): boolean {
    numero = String(numero);

    if (numero.length <= 0 && numero.length < 12) {
        return false;
    }

    // Elimina los espacios en blanco
    numero = numero.replace(/\s/g, '');

    // Inicio algoritmo Luhn
    // 1. Elimina el último dígito
    const ultimoDigito: number = Number(numero[numero.length - 1]);

    // 2. Invierte el numero
    const numeroInvertido: number[] = numero
        .slice(0, numero.length - 1)
        .split('')
        .reverse()
        .map(x => Number(x));

    // 3. + 4. Multiplica por 2 los numeros en posición par
    // Resta 9 si el digito es mayor a 9
    for (let i = 0; i <= numeroInvertido.length - 1; i += 2){
        numeroInvertido[i] = numeroInvertido[i] * 2;
        if (numeroInvertido[i] > 9) {
            numeroInvertido[i] = numeroInvertido[i] - 9;
        }
    }

    // 5. Suma los digitos obtenidos en el paso 4.
    let sumaDigitos = 0;
    sumaDigitos = numeroInvertido.reduce((valorPrevio, valorActual) => (valorPrevio + valorActual), 0);

    // 6. Calcula el módulo sobre 10 de la suma del paso 5 y el último dígito.
    // Si el resultado es 0 entonces es un numero de tarjeta válido
    return ((sumaDigitos + ultimoDigito) % 10 === 0);
}

export function formHasError(formData: UntypedFormGroup, fields: any, formcontrol: string, data: string): any {
    let response: any = '';
    let validate = false;
    let type = '';
    let errors: any = (typeof formData.controls[formcontrol] !== 'undefined') ? formData.controls[formcontrol].errors : [];

    const formcontrolParts = formcontrol.split('.');

    if (typeof formcontrolParts[1] !== 'undefined') {
        let group: any = formData;

        formcontrolParts.forEach(part => {
            group = group.controls[part];
            formcontrol = part;
        });

        errors = (typeof group.errors !== 'undefined') ? group.errors : [];
    }

    for (const error in errors) {
      if (errors[error]) {
        validate = errors[error];
        type = error;
      }
    }

    if (data === 'validate') {
      response = validate;
    } else if (data === 'message'){
      if (type !== '') {
        response = (typeof fields[formcontrol] !== 'undefined') ? fields[formcontrol][type] : '';
      }
    }

    return response;
}
