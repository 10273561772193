import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { ApiService } from 'src/app/platform/services/api.service';
import { PlatformService } from 'src/app/platform/services/platform.service';
import { LocalStorageService } from 'src/app/platform/services/local-storage.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ApiService,
    PlatformService,
    LocalStorageService
  ]
})
export class PlatformModule { }
