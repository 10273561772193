import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// Models
import { NavbarConfig } from 'src/app/platform/models/utils';
import { ScreenLoaderData } from 'src/app/platform/components/ui-screen-loader/ui-screen-loader.model';

// Components
import { UiDialogComponent } from 'src/app/platform/components/ui-dialog/ui-dialog.component';

/**
 * This service is in charge of managing on screen components that can be
 * triggered from several other components. Like toasts, spinners, etc.
 */
@Injectable()
export class UiService {
    public spinnerStatus = new BehaviorSubject<boolean>(false);
    public interfaseShow = new BehaviorSubject<boolean>(false);
    public navbarConfig = new BehaviorSubject<NavbarConfig>(new NavbarConfig());
    public tabIndex = 0;
    public screenLoaderData = new BehaviorSubject<ScreenLoaderData>(new ScreenLoaderData(''));

    constructor(
        private router: Router,
        private location: Location,
        private matSnackBar: MatSnackBar,
        private uiDialog: MatDialog,
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.setNavbarConfig(new NavbarConfig().withTitle(this.getTitleFromRoute()));
            }
        });
    }

    /**
     * Shows a snack bar to display errors and messages.
     */
    public showSnackBar(message: string): void {
        this.matSnackBar.open(message, '', {duration: 1000 * 5});
    }

    public showSpinner(value: boolean): void {
        this.spinnerStatus.next(value);
    }

    public setNavbarConfig(value: NavbarConfig): void {
        this.navbarConfig.next(value);
    }

    public setInterfaseConfig(value: boolean): void {
        this.interfaseShow.next(value);
    }

    public dialogShow(title: string, message: string, type: string = ''): void {
        this.uiDialog.open(UiDialogComponent, {
            width: '350px',
            data: {
                title,
                message,
                type,
                buttonCancelText: '',
                buttonConfirmText: 'Aceptar'
            }
        });
    }

    /**
     * Set screen loader component data.
     */
    public screenLoaderSet(data: ScreenLoaderData): void {
        this.screenLoaderData.next(data);
    }

    /**
     * Get the title form router
     */
    public getTitleFromRoute(): string {
        const currentPath = this.location.path();
        let currentTitle = 'ipTel';

        this.router.config.map((value: any, index) => {
            if (('/' + value.path) === currentPath && value.data !== undefined) {
                currentTitle = value.data.title;
            } else {
                if (typeof value.path !== 'undefined'){
                    if ((value.path.split('/')).pop() === (currentPath.split('/')).pop()) {
                        currentTitle = value.data.title;
                    }
                }
            }
        });

        return currentTitle;
    }
}
