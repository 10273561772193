import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { UiModule } from 'src/app/platform/ui.module';

// Services
import { CommonService } from 'src/app/platform/services/common.service';
import { ZohoService } from 'src/app/platform/services/zoho.service';
import { AuthService } from 'src/app/modules/auth/auth.service';

// Components
import { RegisterComponent } from 'src/app/modules/auth/views/register/register.component';

@NgModule({
  declarations: [
    RegisterComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    FormsModule, 
    ReactiveFormsModule
  ],
  exports: [],
  providers: [
    CommonService,
    ZohoService,
    AuthService,
  ]
})
export class AuthModule { }
