import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

// Services
import { PlatformService } from 'src/app/platform/services/platform.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(
    private router: Router,
    private platformService: PlatformService,
    private gtmService: GoogleTagManagerService,
  ) {
    this.gtmService.addGtmToDom();
  }

  ngOnInit(): void {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
          const gtmTag = {
              event: 'page',
              pageName: item.url
          };

          this.gtmService.pushTag(gtmTag);
      }
    });
  }

  /**
   * Detect screen resizing to configure interface
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.platformService.onWindowResize(event);
  }
}
