import { EventEmitter } from 'events';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { PhoneMaskDirective } from '../utils/phone.directive';

@Injectable()
export class PlatformService {
    public events: EventEmitter = new EventEmitter();
    public screen = new BehaviorSubject<object>({
        width: window.innerWidth,
        height: window.innerHeight
    });

    constructor(
        private location: Location
    ) {}

    onWindowResize(event: any): void {
        this.screen.next({
            width: event.target.innerWidth,
            height: event.target.innerHeight
        });
    }

    onBack(): void {
        this.location.back();
    }
}

@Injectable({
    providedIn: 'root'
  })
  export class ListExportService {
    private generateCVS(header: Array<string>, data: any, name?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let body = header.join(',');

            data.forEach((row: any) => {
                body = body + '\r' + row.join(',');
            });

            resolve(body);
        });
    }

    private generateJSON(header: Array<string>, data: any, name?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve(JSON.stringify(data));
        });
    }

    private generateXLS(header: Array<string>, data: any, name: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet(name);

            worksheet.addRow(header);

            data.forEach((row: any) => {
                const rowData: Array<any> = [];

                row.forEach((column: any) => {
                    rowData.push(column);
                });

                worksheet.addRow(rowData);
            });

            workbook.xlsx.writeBuffer().then((body: any) => {
                resolve(body);
            });
        });
    }

    private header(structure: Array<any>): Array<string> {
        const header: Array<string> = [];

        structure.forEach((item) => {
            header.push(item.header);
        });

        return header;
    }

    private body(structure: Array<any>, data: any): Array<any> {
        const body: Array<any> = [];

        data.forEach((row: any) => {
            const rowNew: any = []

            structure.forEach((item) => {
                rowNew.push(row[item.body]);
            });

            body.push(rowNew);
        });

        return body;
    }

    downloadFile(type: string, name: string, structure: Array<object>, data: any): Promise<any>{
        return new Promise((resolve, reject) => {
            const types: any = {
                cvs: {
                extension: '.cvs',
                mimetype: 'text/csv',
                function: this.generateCVS,
                },
                xls: {
                extension: '.xlsx',
                mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                function: this.generateXLS,
                },
                json: {
                extension: '.json',
                mimetype: 'text/json',
                function: this.generateJSON,
                },
            };

            const format = types[type];
            const header = this.header(structure);
            const body = this.body(structure, data);

            format.function(header, ((type === 'json') ? data : body), name).then((list: any) => {
                const blob = new Blob([list], { type: format.mimetype });

                saveAs(blob, name + format.extension);

                resolve(true);
            });
        });
    }
}
