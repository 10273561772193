export class ResponseData {
    status: boolean;
    message: string;
    result: any;

    constructor(status: boolean, message: string, result: any) {
        this.status = status;
        this.message = (typeof message !== 'undefined' && message !== '') ? message : '';
        this.result = result;
    }
}

export class PaginatedData {
    registers: any;
    registersTotal: number;
    page: number;
    pageSize: number;
    pageTotal: number;

    constructor(registers: any, total: number, pageSize: number, pages: number, page: number) {
        this.registers = registers;
        this.registersTotal = total;
        this.page = page;
        this.pageSize = pageSize;
        this.pageTotal = pages;
    }
}

export class MetaData {
    public id: number;
    public name: string;

    constructor(id?: number, name?: string) {
        this.id = (typeof id !== 'undefined' && id > 0) ? id : 0;
        this.name = (typeof name !== 'undefined' && name !== '') ? name : '';
    }

    configData(parameters: any): MetaData{
        this.id = parameters.id;
        this.name = parameters.nombre;

        return this;
    }
}

export class MenuItem {
    public menu?: string;
    public icon?: string;
    public route?: string;
    public title?: string;
    public action?: () => void;
}

export class NavbarConfig {
    public title: string;
    public navbarShow: boolean;
    public navbarDouble: boolean;
    public menuButtonShow: boolean;

    constructor() {
        this.title = 'IpTel';
        this.navbarShow = true;
        this.navbarDouble = false;
        this.menuButtonShow = true;
    }

    public onDefaultActionButton(): void {
        console.log('Action Button was pressed');
    }

    // Pseudo-Builder design pattern implementation (simplified maybe a little too much)
    withTitle(value: string): NavbarConfig {
        this.title = value;
        return this;
    }

    navbarDisplay(value: boolean): NavbarConfig {
        this.navbarShow = value;
        return this;
    }

    navbarHasDouble(value: boolean): NavbarConfig {
        this.navbarDouble = value;
        return this;
    }

    menuButtonDisplay(value: boolean): NavbarConfig {
        this.menuButtonShow = value;
        return this;
    }
}

/**
 * This enum contains the possible events that can be sent or be obtained
 * from the android app.
 */
export enum PlatformEvent {
    GET_DEVICE_ID = 'GetDeviceId',
    IMAGE_READ = 'ReadImage',
    IMAGE_TAKE = 'TakePicture',
    IMAGE_FRAGMENT = 'GetImageFragment',
    CLOSE_APP = 'CloseApp',
    GET_GPS = 'GetGPS',
    GO_BACK = 'GoBack',
    ERROR = 'Error'
}

export class PriceData {
    public id = 0;
    public name = '';

    public licenses = 0;
    public licenseValue = 0;
    public licenseValueIVA = 0;
    public licenseValueTotal = 0;
        
    public subtotal = 0;
    public iva = 0;
    public ivaPercent = 0;

    public totalBefore = 0;
    public totalAfter = 0;
        
    public discountLaterValue = 0;
    public discountLaterPercent = 0;

    public discountInitialValue = 0;
    public discountInitialPercent = 0;

    public promotion = false;
    public promotionValue = 0;
    public promotionPercent = 0;
    public promotionDuration = 0;

    constructor(parameters?: any, promotion?: boolean) {
        console.log(parameters);
        
        if(typeof parameters !== 'undefined'){
            this.id = parameters.id;
            this.name = parameters.nombre;
            this.licenses = parameters.licencias;

            this.licenseValue = parameters.licenciaPrecio;
            this.licenseValueIVA = parameters.licenciaPrecioIVA;

            this.discountInitialValue = this.licenseValue * (parameters.descuentoInicialPorcentaje / 100);
            this.discountInitialPercent = parameters.descuentoInicialPorcentaje;
            
            this.discountLaterValue = (this.licenseValue * (parameters.descuentoPosteriorPorcentaje / 100)) * (this.licenses - 2);
            this.discountLaterPercent = parameters.descuentoPosteriorPorcentaje;

            this.subtotal = (this.licenses * this.licenseValue) - this.discountInitialValue - ((this.licenses >= 3) ? this.discountLaterValue : 0);            
            this.iva = this.subtotal * (parameters.ivaPorcentaje / 100);
            this.ivaPercent = parameters.ivaPorcentaje;

            this.totalBefore = this.totalIva(this.subtotal);
            this.totalAfter = this.totalIva((this.licenses * this.licenseValue) - ((this.licenses >= 3) ? this.discountLaterValue : 0));
        
            this.promotion = parameters.promocion ?? (promotion ?? false);
            this.promotionPercent = parameters.promocionPorcentaje ?? 50;
            this.promotionDuration = parameters.promocionDuracion ?? 3;

            this.promotionValue = (this.licenseValue * (this.promotionPercent / 100));

            if(this.promotion){                
                this.subtotal = this.licenses * (this.licenseValue - this.promotionValue);
                this.totalBefore = this.totalIva(this.subtotal);
                this.totalAfter = this.totalIva(this.licenses * this.licenseValue);
            }

            console.log(this);
        }
    }

    totalIva(ammount: number){
        return ammount + (ammount * (this.ivaPercent / 100));
    }

    getData(name: string, field: string){
        let data: {[key:string]: any} = {
            'Empresa': {id: 1, licenses: 3},
            'Comercios Plus': {id: 2, licenses: 2},
            'Comercios': {id: 3, licenses: 5},
            'Profesionales': {id: 4, licenses: 1},
        }

        return data[name][field];
    }
}