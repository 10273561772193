import { Injectable } from '@angular/core';
import { ResponseData } from 'src/app/platform/models/utils';
import { ApiService } from 'src/app/platform/services/api.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthService {
    
    constructor(
        private apiService: ApiService 
    ) {}

    login(email:string, clave:string): Promise<ResponseData> {
        return new Promise((resolve, reject) => {
            const data = {
                'usuario': email,
                'password': CryptoJS.MD5(clave).toString().toUpperCase(),
            };
            
            const headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
            };

            this.apiService.request('post', 'home/login', data, headers).toPromise().then((success: any) => {
                if(success.code == 'SUCCESS'){
                    resolve(this.apiService.successDataPrepare(success.message, success));
                } else {
                    reject(this.apiService.errorDataPrepare(success.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.message, error));
            });
        });
    }
    
    register(
        name: string, 
        email: string, 
        phone: string, 
        rubro: number, 
        cuit: number, 
        taxCondition: number, 
        province: number, 
        cbu:number, 
        licences: number, 
        password: string
    ): Promise<ResponseData> {
        let data = {
			'nombre': name.split(' ')[0],
			'apellido': name.substr(name.indexOf(' ') + 1),
			'email': email,
			'telefono': phone,
			'idRubro': rubro,
			'cuit': cuit,
			'idCondicionFiscal': taxCondition,
			'idProvincia': province,
			'cbu': cbu,
			'cantidadLicencias': licences,
			'password': CryptoJS.MD5(password).toString(),
		};
        
        return new Promise((resolve, reject) => {
            this.apiService.request('post', 'register/RegistroNuevo', data).toPromise().then((success: any) => {
                if(success.message != 'ok' && success.message != '' && typeof success.message != 'undefined'){
                    reject(this.apiService.errorDataPrepare(success.message, success));
                } else {
                    resolve(this.apiService.successDataPrepare('', success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.message, error));
            });
        });
    }

    registerFull(data: {
        name: string;
        email: string;
        phone: string;
        rubro: number;
        cuit: number;
        taxCondition: number;
        province: number;
        country: number;
        cbu: number;
        licenses: number;
        password?: string;
        role?: string;
        search?: string;
        agent?: string;
        referer: string;
    }): Promise<ResponseData> {
        const body: any = {
			nombre: data.name.split(' ')[0],
			apellido: data.name.substr(data.name.indexOf(' ') + 1),
			email: data.email,
			telefono: data.phone,
			idRubro: data.rubro,
			cuit: data.cuit,
			idCondicionFiscal: data.taxCondition,
			idProvincia: data.province,
			cbu: data.cbu,
			cantidadLicencias: data.licenses,
            provincia: data.province,
            pais: data.country,
            role: (typeof data.role !== 'undefined') ? data.role : '',
            search: (typeof data.search !== 'undefined') ? data.search : '',
            agent: (typeof data.agent !== 'undefined') ? data.agent : '',
            referer: data.referer, //'Registro plataforma'
            tipo: 'Video demo',
		};

        if(typeof data.password !== 'undefined' && data.password !== ''){
            body.password = CryptoJS.MD5(data.password).toString();
        }

        return new Promise((resolve, reject) => {
            this.apiService.request('post', 'register/RegistroNuevo', body).toPromise().then((success: any) => {
                if(success.message !== 'ok' && success.message !== '' && typeof success.message !== 'undefined'){
                    reject(this.apiService.errorDataPrepare(success.message, success));
                } else {
                    resolve(this.apiService.successDataPrepare('', success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.message, error));
            });
        });
    }
}
