import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { RegisterComponent } from './modules/auth/views/register/register.component';

const routes: Routes = [
  { path: '', component: RegisterComponent, data: { title: 'Alta en Control Comercio' } },
  { path: 'registro', component: RegisterComponent, data: { title: 'Alta en Control Comercio' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
