import { Injectable } from '@angular/core';

// Models
import { MetaData, ResponseData } from 'src/app/platform/models/utils';

// Services
import { ApiService } from 'src/app/platform/services/api.service';

@Injectable()
export class CommonService {
    
    constructor(
        private apiService: ApiService 
    ) {}

    pricesGet(locale: string = 'es_AR'): Promise<ResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'register/getplanes?localizacion=' + locale).toPromise().then((success: any) => {
                if(success.message == 'ok'){
                    resolve(this.apiService.successDataPrepare('', success.data));
                } else {
                    reject(this.apiService.errorDataPrepare(success.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.message, error));
            });
        });
    }

    areasGet(): Promise<ResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'home/getallrubros', {}).toPromise().then((success: any) => {
                const itemsData: Array<MetaData> = [];

                success.results.forEach((value: any, index: number) => {
                    itemsData.push(new MetaData().configData(value));
                });
                    
                resolve(this.apiService.successDataPrepare('', itemsData));
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.message, error));
            });
        });
    }

    provincesGet(): Promise<ResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'home/getallprovincias', {}).toPromise().then((success: any) => {
                const itemsData: Array<MetaData> = [];

                success.results.forEach((value: any, index: number) => {
                    itemsData.push(new MetaData().configData(value));
                });
                    
                resolve(this.apiService.successDataPrepare('', itemsData));
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.message, error));
            });
        });
    }

    taxConditionsGet(): Promise<ResponseData> {
        return new Promise((resolve, reject) => {
            const defaultData: Array<MetaData> = [
				new MetaData(1, 'Responsable inscripto'),
				new MetaData(3, 'Responsable Monotributo'),
				new MetaData(4, 'Exento'),
			];
			
			resolve(this.apiService.successDataPrepare('', defaultData));
			
			/*
			this.apiService.request('get', 'home/getallcondicionesfiscales', {}).toPromise().then((success: any) => {
                resolve(this.apiService.successDataPrepare('', success.results));
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.message, error));
            });
			*/
        });
    }

	agentsGet(): Promise<ResponseData> {
        return new Promise((resolve, reject) => {
            const defaultData = ['Directo', 'Mariana Fernandez', 'Luciana Samuel', 'Orlando Rodriguez', 'Graciela Prieto', 'Inés Liamp', 'Ignacio Sobrera', 'Diana Rodriguez Garcia', 'Ana Helguera', 'Sebastian Figuero', 'Gonzalo Laime'];
			
			resolve(this.apiService.successDataPrepare('', defaultData));
        });
    }
}
