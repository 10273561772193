import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-ui-bottom-sheet',
  templateUrl: './ui-bottom-sheet.component.html',
  styleUrls: ['./ui-bottom-sheet.component.scss']
})
export class UiBottomSheetComponent implements OnInit {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<UiBottomSheetComponent>
  ) {}

  ngOnInit(): void { }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
