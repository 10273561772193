import { Component, OnInit } from '@angular/core';

// Services
import { UiService } from 'src/app/platform/services/ui.service';

@Component({
  selector: 'app-ui-spinner',
  templateUrl: './ui-spinner.component.html',
  styleUrls: ['./ui-spinner.component.css']
})
export class UiSpinnerComponent implements OnInit {
  public showSpinner = false;

  constructor(
    private uiService: UiService
  ) {
    this.uiService.spinnerStatus.subscribe((value) => {
      this.showSpinner = value;
    });
  }

  ngOnInit(): void { }
}
