import { Injectable } from '@angular/core';

// Models
import { ResponseData } from 'src/app/platform/models/utils';

// Services
import { ApiService } from 'src/app/platform/services/api.service';

@Injectable()
export class ZohoService {
    
    constructor(
        private apiService: ApiService 
    ) {}    

    conditions(condition: any, type: string): string {
        let conditions: {[key:string]: { [key:string]: any }} = {
            'tax': {
                '0': '-None-',
                '1': 'Responsable Inscripto',
                '3': 'Responsable Monotributo',
                '4': 'Exento',
            }, 
            'iva': {
                '0': '-None-',
                '1': '1: Responsable Inscripto',
                '3': '3: Responsable Monotributo',
                '4': '4: Consumidor Final',
            },
            'invoices': {
                '0': '-None-',
                '1': '1: A',
                '3': '1: A',
                '4': '2: B',
            },
            'planCode': {
                'desktop': 1,
                'web': 2,
                'app': 3,
            },
            'product': {
                'desktop': 'Servicio Control Comercio desktop',
                'web': 'Servicio Control Comercio web',
                'app': 'Servicio Control Comercio app',
            }
        }
        
        if(type in conditions){
            if(typeof conditions[type][condition] !== 'undefined'){
                return conditions[type][condition]
            } else {
                return conditions[type][0]
            }
        }
        
        return '';
    }
    
    formRegister(
        formType: string,
        name: string,
        email: string, 
        phone: string, 
        entry: string, 
        cuit: number, 
        taxCondition: number, 
        province: string, 
        country: string, 
        agent: string, 
        cbu:number, 
        licences: number,
    ): Promise<ResponseData> {
        const countryCode = ((country === 'Chile') ? '+569' : '+549');
        phone = countryCode + phone.replace(countryCode, '');

        let data = {
            'name': name,
            'email': email,
            'phone': phone,
            'rubre': entry,
            'product': formType, 
            'planName': this.conditions(formType, 'product'), 
            'planCode': this.conditions(formType, 'planCode'), 
            'licences': licences, 
            'cuit': cuit, 
            'cbu': cbu,
            'taxCondition': this.conditions(taxCondition, 'tax'), 
            'ivaCondition': this.conditions(taxCondition, 'iva'), 
            'invoiceType': this.conditions(taxCondition, 'invoices'),
            'province': province,
            'country': country,
            'agent': (typeof agent !== 'undefined') ? agent : '',
            'referer': (typeof window.location.href !== 'undefined' && window.location.href !== '') ? window.location.href : 'https://registro.controlcomercio.com',
		};
        
        console.log(data);
        
        return new Promise((resolve, reject) => {            
            this.apiService.request('post', 'zoho/v2/contact', data, { 'Content-Type': 'application/x-www-form-urlencoded' }, 'https://controlcomercio.com/wp-json/').toPromise().then((success: any) => {
				if(success.status){
                    resolve(this.apiService.successDataPrepare(success.message, success.data));
                } else {
                    reject(this.apiService.errorDataPrepare(success.message, success.data));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare((typeof error.message.data !== 'undefined') ? error.message.data : '', error));
            });
        });
    };	
}
