import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { MaterialModule } from 'src/app/platform/material.module';

// Services
import { UiService } from 'src/app/platform/services/ui.service';

// Components
import { UiDialogComponent } from 'src/app/platform/components/ui-dialog/ui-dialog.component';
import { UiSpinnerComponent } from 'src/app/platform/components/ui-spinner/ui-spinner.component';
import { UiBottomSheetComponent } from 'src/app/platform/components/ui-bottom-sheet/ui-bottom-sheet.component';
import { UiScreenLoaderComponent } from 'src/app/platform/components/ui-screen-loader/ui-screen-loader.component';

@NgModule({
  declarations: [
    UiDialogComponent,
    UiSpinnerComponent,
    UiBottomSheetComponent,
    UiScreenLoaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
  ],
  exports: [
    MaterialModule,
    UiDialogComponent,
    UiSpinnerComponent,
    UiBottomSheetComponent,
    UiScreenLoaderComponent,
  ],
  providers: [
    UiService
  ]
})

export class UiModule { }
