import { Component, Input, OnInit } from '@angular/core';

// Models
import { ScreenLoaderData } from 'src/app/platform/components/ui-screen-loader/ui-screen-loader.model';

// Services
import { UiService } from 'src/app/platform/services/ui.service';

@Component({
  selector: 'ui-screen-loader',
  templateUrl: './ui-screen-loader.component.html',
  styleUrls: ['./ui-screen-loader.component.scss']
})
export class UiScreenLoaderComponent implements OnInit {
  public data: ScreenLoaderData = new ScreenLoaderData('');

  constructor(
    private uiService: UiService
  ) {
    this.uiService.screenLoaderData.subscribe((value: ScreenLoaderData) => {
      this.data = value;
    });
  }

  ngOnInit(): void { }
}
